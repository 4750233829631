import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/Loyout"
import SEO from "../components/seo"
import HeroBook from "../components/HeroBook/HeroBook"
import Reviews from "../components/Reviews"
import StaticReviews from "../components/StaticReviews"
import ParisBottom from "../components/ParisBottom"
import config from "../config"

const BookPage = () => {
  const data = useStaticQuery(graphql`
    query {
      italianAffair: file(
        relativePath: { eq: "caroline-montague_header-an-italian-affair.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      italianAffairThumb: file(
        relativePath: {
          eq: "caroline-montague_book-cover-an-italian-affair.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spanishSun: file(
        relativePath: { eq: "caroline-montague_shadows-over-the-spanish-sun-background.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spanishSunThumb: file(
        relativePath: {
          eq: "caroline-montague_shadows-over-the-spanish-sun.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      parisSecret: file(
        relativePath: { eq: "caroline-montague_page-link-a-paris-secret.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      parisSecretThumb: file(
        relativePath: { eq: "caroline-montague_book-cover-a-paris-secret.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const reviews = config.reviews.filter(
    review => review.book === "italianAffair"
  )
  const staticReviews = config.staticRevews.filter(
    review => review.book === "italianAffair"
  )
  return (
    <Layout>
      <SEO
        title="An Italian Affair - Caroline Montague"
        description="A sweeping tale of love, betrayal and war, set against the glorious Tuscan countryside. Perfect for fans of Santa Montefiore and Dinah Jeffries"
      />
      <HeroBook data={data} />
      <StaticReviews reviews={staticReviews} />
      <Reviews reviews={reviews} />
      <ParisBottom data={data} />
    </Layout>
  )
}

export default BookPage
