/** @jsx jsx */
import { useState } from "react"
import Img from "gatsby-image"
import { jsx } from "@emotion/core"
import { animated, useSpring } from "react-spring"
import config from "../../config"

const HeroBook = ({ data }) => {
  const [isLinkActive, setLinkActive] = useState(false)
  const [isThumbActive, setThumbActive] = useState(false)

  const zoom = useSpring({
    transform: isLinkActive ? `scale(1.05)` : `scale(1)`,
  })
  const animationThumb = useSpring({
    transform: isThumbActive ? `scale(1.05)` : `scale(1)`,
  })

  const up = useSpring({
    to: [{ transform: "translateY(0)" }],
    from: { transform: "translateY(100px)" },
  })
  return (
    <div>
      <div
        className="relative"
        css={{
          height: "500px",
        }}
      >
        <div className="absolute h-full w-full overflow-hidden">
          <Img
            className="object-cover h-full w-full"
            fluid={data.italianAffair.childImageSharp.fluid}
          />
        </div>
        <div
          css={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "block",
            top: "0",
            left: "0",
            backgroundColor: "rgba(62, 178, 175, 0.95);",
          }}
        ></div>
        <div className=" w-full h-full justify-start align-top flex flex-col">
          <h1 className="z-50 w-full text-5xl text-center text-white mt-20 font-heading">
            An Italian Affair
          </h1>
          <div className="z-50 flex flex-row justify-center mt-4">
            <a
              href={config.buylinks.italianLink}
              rel="nofollow noopener"
              target="_blank"
              className="inline-block text-white hover:text-black z-50"
              css={{
                transition: "0.7s all",
              }}
            >
              Buy now >
            </a>
          </div>
        </div>
      </div>
      <animated.div
        style={up}
        className="z-50 flex flex-row justify-center  -mt-64 mx-auto w-1/2 md:w-1/4 h-auto z-50"
      >
        <animated.a
          href={config.buylinks.italianLink}
          rel="nofollow noopener"
          target="_blank"
          className="inline-block w-full h-full"
          style={animationThumb}
          onMouseEnter={() => setThumbActive(true)}
          onMouseLeave={() => setThumbActive(false)}
        >
          <Img
            className="object-cover z-50 w-full h-full"
            css={{
              boxShadow: "-3px 3px 5px rgba(0 ,0, 0, 0.15);",
            }}
            fluid={data.italianAffairThumb.childImageSharp.fluid}
          />
        </animated.a>
      </animated.div>
      <animated.div
        style={up}
        className="max-w-3xl text-center mx-auto mt-16 text-xl md:text-2xl px-8 md:px-0 mb-8 md:mb-0 text-secondary font-semibold"
      >
        <p>
          A sweeping tale of love, betrayal and war, set against the glorious
          Tuscan countryside. Perfect for fans of Santa Montefiore and Dinah
          Jeffries
        </p>
      </animated.div>
      <animated.div
        style={up}
        className="hidden lg:block my-16 w-0 mx-auto border-r border-gray-100 h-16"
      ></animated.div>
      <div className="max-w-3xl text-center mx-auto px-8 md:px-0">
        <p className="mb-8 inline-block font-semibold">
          Love. War. Family. Betrayal.
        </p>
        <p className="mb-8 inline-block">
          Italy, 1937. Alessandra Durante is grieving the loss of her husband
          when she discovers she has inherited her ancestral family seat, Villa
          Durante, deep in the Tuscan Hills. Longing for a new start, she moves
          from her home in London to Italy with her daughter Diana and sets
          about rebuilding her life.
        </p>
        <p className="mb-8 inline-block">
          Under the threat of war, Alessandra’s house becomes first a home and
          then a shelter to all those who need it. Then Davide, a young man who
          is hiding the truth about who he is, arrives, and Diana starts to find
          her heart going where her head knows it must not.
        </p>
        <p className="mb-8 inline-block">
          Back home in Britain as war breaks out, Alessandra’s son Robert, signs
          up to be a pilot, determined to play his part in freeing Italy from
          the grip of Fascism. His bravery marks him out as an asset to the
          Allies, and soon he is being sent deep undercover and further into
          danger than ever before.
        </p>

        <p className="inline-block font-semibold">
          As war rages, the Durante family will love and lose, but will they
          survive the war…?
        </p>
      </div>
      <div className="pb-20">
        <animated.div
          style={zoom}
          onMouseEnter={() => setLinkActive(true)}
          onMouseLeave={() => setLinkActive(false)}
          className="w-40 mt-20 h-40 text-center mx-auto flex justify-center align-middle flex-col text-white"
          css={{
            borderRadius: "50%",
            backgroundColor: "#39AFAC",
          }}
        >
          <a
            href={config.buylinks.italianLink}
            rel="nofollow noopener"
            target="_blank"
            css={{
              display: "flex",
              flexFlow: "column",
              height: "100%",
              width: "100%",
              verticalAlign: "middle",
              justifyContent: "center",
            }}
          >
            <span className="inline-block">Buy now ></span>
          </a>
        </animated.div>
      </div>
    </div>
  )
}

export default HeroBook
